<template>
  <div class="player" :style="cssVars">
    <audio
      v-if="file"
      :id="'player-' + uniqueId"
      ref="player"
      class="player"
      controls
	  @pause="onPause"
    >
      <source :src="audioSource" :type="audioType" >
    </audio>
    <div v-if="file" class="player-controls">
      <div class="player-buttons">
        <button
          v-show="showControls"
          class="player-button backward"
          @mousedown="backwardAudio()"
        >
          <i class="play mm-icon mm-icon-rewind white big" />
        </button>
        <div class="active-border">
          <button
            class="player-button play"
            @click.prevent="playing = !playing"
          >
            <i v-show="playing" class="pause mm-icon mm-icon-pause white big" />
            <i v-show="!playing" class="play mm-icon mm-icon-play white big" />
          </button>
        </div>
        <button
          v-show="showControls"
          class="player-button forward"
          @mousedown="forwardAudio()"
        >
          <i class="play mm-icon mm-icon-forward white big" />
        </button>
      </div>
      <p v-if="showTimer" v-show="showControls" class="player-timer">
        -{{ (durationSeconds + 1 - currentSeconds) | convertTimeHHMMSS }}
      </p>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {uniqueId} from "lodash";
const {debug} = require("@/misc/debug");
export default {
	name: "AudioPlayer",
	
	filters: {
		convertTimeHHMMSS(val) {
			let time = new Date(val * 1000).toISOString().substr(11, 8);
			
			return time.indexOf("00:") === 0 ? time.substr(3) : time;
		}
	},
	
	props: {
		file: {
			type: [String, Object, Blob],
			default: null
		},
		autoPlay: {
			type: Boolean,
			default: true,
		},
		hideControlsUntilPlay: {
			type: Boolean,
			default: false,
		},
		showTimer: {
			type: Boolean,
			default: true,
		},
		textColor: {
			type: String,
			default: '#FFFFFF',
		},
		iconsColor: {
			type: String,
			default: '#FFFFFF',
		},
		additionalInformation: {
			type: Array,
			default: null
		}
	},
	
	data: () => ({
    uniqueId: uniqueId(),
		audio: undefined,
		playing: false,
		currentSeconds: 0,
		durationSeconds: 0,
		loaded: false,
		showControls: false,
		showAdditionalInformation: false,
		resources: [],
    	isBlob: false
	}),
	
	computed: {
		...mapState({
			isNetworkOnline: state => state.app.isNetworkOnline,
			museum: state => state.museum.museum,
		}),
    // Determinar la fuente del audio
    audioSource() {
      if (this.file instanceof Blob) {
        return URL.createObjectURL(this.file);
      } else if (typeof this.file === "string") {
        return this.file;
      } else {
        return "";
      }
    },

    audioType() {
      return this.isBlob ? "audio/mpeg" : "";
    },
		cssVars() {
			// See details about the play button percentage coloring here https://stackoverflow.com/a/21198231
			let percentage = (this.currentSeconds * 100) / this.durationSeconds;
			if((this.currentSeconds-1) >= this.durationSeconds) {
				percentage = 0;
			}
			
			let angle = (percentage * 360) / 100;
			const degrees = 360;
			
			if (angle < 0) {
				angle = 0;
			}
			if (angle > degrees) {
				angle = degrees;
			}
			
			let border = (angle <= 180)
				? 'linear-gradient(' + (90 + angle) + 'deg, transparent 50%, rgb(150, 150, 150) 50%), ' +
				'linear-gradient(90deg, rgb(150, 150, 150) 50%, transparent 50%)'
				
				: 'linear-gradient(' + (angle - 90) + 'deg, transparent 50%, white 50%), ' +
				'linear-gradient(90deg, rgb(150, 150, 150) 50%, transparent 50%)';
			
			return {
				'--border': border,
				'--textColor': this.textColor,
				'--iconsColor': this.iconsColor,
			};
		},
	},
	
	watch: {
		playing(value) {
		if (value) {
			this.audio.play();
		} else {
			this.audio.pause();
		}
		},
		file: {
		immediate: true,
		handler() {
			this.$nextTick(() => {
			this.$refs.player.load();
			});
		}
		}
	},
	mounted() {
    this.audio = this.$refs.player;

    if (this.audio) {
      this.audio.addEventListener('timeupdate', this.update);
      this.audio.addEventListener('loadeddata', this.load);
      this.audio.addEventListener('pause', this.onPause);
      this.audio.addEventListener('play', this.onPlay);
	  this.$root.$on('pause-audio', this.onPause);
    }
  },
  beforeDestroy() {
	this.$root.$off('pause-audio', this.onPause);
  },
  methods: {
    load() {
      if (this.audio.readyState >= 2) {
        if (this.additionalInformation && this.additionalInformation.length > 0) {
          this.resources = this.additionalInformation.map(resource => {
            const startTime = parseInt(resource.startTime.split(':')[0]) * 60 + parseInt(resource.startTime.split(':')[1]);
            const endTime = parseInt(resource.endTime.split(':')[0]) * 60 + parseInt(resource.endTime.split(':')[1]);
            return { ...resource, startTime, endTime };
          }).sort((a, b) => a.startTime - b.startTime);
        }
        this.loaded = true;
        this.durationSeconds = parseInt(this.audio.duration);
        if (this.museum.code !== "MPICASSOM") {
          this.playing = this.autoPlay;
        }
      }
    },

    update() {
      this.currentSeconds = parseInt(this.audio.currentTime);
      if (this.resources.length > 0 && this.isNetworkOnline) {
        const resource = this.resources[0];
        if (!this.showAdditionalInformation && resource.startTime === this.currentSeconds) {
          this.showAdditionalInformation = true;
          this.showResourceInformation(resource);
        } else if (this.showAdditionalInformation && resource.endTime === this.currentSeconds) {
          if (resource.resourceType === 'TEXT' || resource.resourceType === 'IMAGE') {
            this.$swal.close();
          }
        }
      }
    },

    showResourceInformation(resource) {
      this.$swal({
        title: `<span>${resource.title}</span>`,
        html: `${this.getResource(resource)}`,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        padding: '15px 10px 10px 10px',
        imageUrl: resource.resourceType === 'IMAGE' ? resource.url : '',
        imageWidth: '100%',
        imageHeight: '100%',
        onBeforeOpen: () => {
          if (resource.resourceType !== 'TEXT' && resource.resourceType !== 'IMAGE') {
            this.audio.pause();
          }
        },
        onDestroy: () => {
          if (resource.resourceType !== 'TEXT' && resource.resourceType !== 'IMAGE') {
            this.audio.play();
          }
          this.resources.shift();
          this.showAdditionalInformation = false;
        }
      });
    },

	onPause() {
        this.playing = false;
        if (this.audio) {
            this.audio.pause();
        }
        this.$emit('audioPaused');
        if (this.currentSeconds === this.durationSeconds) {
            this.currentSeconds = 0;
            this.$emit('audioFinished');
        }
    },

    onPlay() {
      this.showControls = true;
      this.playing = true;
      this.$emit('audioPlayed');
    },

    togglePlay() {
      this.playing = !this.playing;
    },

    backwardAudio() {
      this.audio.currentTime -= 2;
      this.$emit('audioBackward');
    },

    forwardAudio() {
      this.audio.currentTime += 2;
      this.$emit('audioForward');
    },

    getResource(resource) {
      if (resource.resourceType === 'AUDIO') {
        return `<audio v-if="file" :id="'player-' + ${this.file}" ref="player" class="player" controls>
                  <source :src="${this.file}" type="audio/mpeg">
                </audio>`;
      } else if (resource.resourceType === 'VIDEO') {
        return `<video controls style="width: 100%;">
                  <source src="${resource.localizations[0].url}" type="video/mp4">
                </video>`;
      } else if (resource.resourceType === 'TEXT') {
        return `<div style="height:100%; overflow-y: scroll;"><span style="font-size: 13px;">${resource.localizations[0].content}</span></div>`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.player {
	audio {
		display: none;
	}
	
	.player-controls {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		
		.player-buttons {
			width: 55%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 10px;
			height: 65px;
			
			/* See details about the play button percentage coloring here https://stackoverflow.com/a/21198231 */
			.active-border {
				position: relative;
				text-align: center;
				width: 65px;
				min-width: 65px;
				height: 65px;
				min-height: 65px;
				border-radius: 100%;
				
				background-color: var(--iconsColor);
				background-image: var(--border);
				
				animation: linear 1s;
			}
			
			.player-button {
				background-color: transparent;
				border: none;
				z-index: 1;
				
				&:focus {
					outline: none;
				}
				
				&.backward,
				&.forward {
					.mm-icon {
						color: var(--iconsColor);
					}
				}
				
				&.backward {
					margin-right: 20px;
				}
				
				&.forward {
					margin-left: 20px;
				}
				
				&.play {
					position: relative;
					top: 4px;
					left: 0;
					text-align: center;
					width: 57px;
					height: 57px;
					border-radius: 100%;
					background-color: rgb(73, 73, 73);
					
					.mm-icon.play, .mm-icon.pause {
						color: var(--iconsColor);
						position: absolute;
						left: 11px;
						top: 12px;
					}
				}
			}
		}
		
		.player-timer {
			font-size: 12px;
			color: var(--textColor);
			z-index: 1;
		}
	}
}
</style>
