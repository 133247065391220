/**
 * Transforms a blob into a Base64 string
 * @param {Blob} blob
 * @returns {Promise<string>}
 */
let countingAudios = 0;
let countingImages = 0;
let arrayUrls=[];
let audioJson;
  const helpers = {
	
	/**
	 * Recursively transforms every url in an object to a Blob
	 * @param {Object} element
	 * @param {string} parentProperty
	 * @returns {Promise<void>}
	 */
	transform: async (element, parentProperty) => {
	  if (process.env.VUE_APP_INFO_MODE == "true") {
		console.debug("[transform] Checking " + parentProperty, Object.assign({}, element));
	  }

	  try {
		for (let property in element) {

			
		  if (element.hasOwnProperty(property)) {
			if (typeof element[property] === 'string' && element[property].substring(0, 4) === 'http') {

				 if(element.mainAudioUrl && element.mainAudioUrl !==undefined ){
					let audioJson = element.mainAudioUrl;
					arrayUrls.push(audioJson);
				 	console.debug('Url de audio',element.mainAudioUrl);
	
				 }
				 
				
			  let blob = await fetch(element[property]).then(r => r.blob());
			  if (blob.type === "image/jpeg"){
				countingImages = countingImages + 1;

			  }
			  else if(blob.type === "audio/mpeg"){
				countingAudios = countingAudios + 1;
				const url = URL.createObjectURL(blob);
				console.debug('blob convertido a url',url);
				// arrayUrls.push(url);
				// 

			  }
			  element[property] = blob;
			  if (process.env.VUE_APP_INFO_MODE == "true") {
				console.debug("[transform] Transformed to Blob " + property + " from " + parentProperty);
			  }
			}
              
			if (element[property] !== null
			  && !(element[property] instanceof Blob)
			  && element[property] instanceof Object
			  && property !== 'tags'
			  && property !== 'slides'
			  && property !== 'beacons') {

			  await helpers.transform(element[property], property);
			}
		  }
		 
		}
		
		
		let dataexists = localStorage.getItem('countImages');
		let information = {
			countingAudios,
			countingImages
		  };
		if (dataexists) {
			let data = JSON.parse(dataexists);
			Object.assign(data, information);
			let combinedData = {
				countingAudios: information.countingAudios + data.countingAudios,
				countingImages: information.countingImages + data.countingImages
			  };
			localStorage.setItem('countImages',JSON.stringify(combinedData));
		  } else {
			
			localStorage.setItem('countImages', JSON.stringify(information));
		  }
		  
		  
		// for (let i = 0; i < arrayUrls.length; i++) {
		// 	console.debug('URLS AUDIO',arrayUrls[i]);

		// }
		return element;
	  } catch(e) {
		console.error(e);
	  }
	},

 
	/**
	 * Transform Blob properties to usable URLs
	 * @param {Object} object
	 * @returns {Promise<void>}
	 */
	propertiesToUrl: async (object) => {
	  try {
		for (let property in object) {
		  if (object.hasOwnProperty(property)) {
			/*if (object[property] instanceof Blob) {
			  if (process.env.VUE_APP_INFO_MODE == "true") {
				console.debug("[propertiesToUrl] Checking ", Object.assign({}, object));
			  }
			  // object[property] = await blobToBase64(object[property]);
			  if (process.env.VUE_APP_INFO_MODE == "true") {
				console.debug("[propertiesToUrl] Transformed to URL ", object[property]);
			  }
			}*/

			if (object[property] !== null
			  && object[property] instanceof Object) {
			  await helpers.propertiesToUrl(object[property]);
			}
		  }
		}
	  } catch(e) {
		console.error(e);
	  }
	}
  };

  export default helpers;
